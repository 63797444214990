h3.service-title {
  display: inline;
}
.service-icon {
  color: var(--primary-color);
  height: 100px;
  text-align: center;
}
.service-icon:hover {
  color: var(--secondary-color);
}

.service-description {
  text-align: justify;
  min-height: 280px;
  word-spacing: -2px;
}

.bullet-points {
  list-style-image: url("./images/bulletPoint.png");
  margin-left: 10;
  padding-left: 1em;
  text-indent: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .service-description {
    text-align: justify;
    min-height: 140px;
    word-spacing: -2px;
  }
}

@media only screen and (min-width: 1280px) {
  .service-description {
    text-align: justify;
    min-height: 350px;
    word-spacing: -2px;
  }
}
