.blogImgCenter {
  background: no-repeat;
  height: 400px;
  background-size: cover;
}

.card-text {
  text-align: justify;
  white-space: pre-line;
}
