.certificate-images {
  height: 300px;
  background: no-repeat;
  background-size: contain;
}

.modal-cert {
  background-color: none;
}

.modal-img {
  height: 1100px;
  width: 700px;
  position: static;
}
.modal-content {
  background-color: transparent;
}

.cert-button {
  background-color: transparent;
}
.cert-button:hover {
  background-color: transparent;
  border-width: 2px;
  border-color: var(--secondary-color);
}
.certificate {
  padding: 10px;
}
@media only screen and (max-width: 767px) {
  .modal-img {
    width: 40vh;
    height: auto;
    position: static;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .modal-img {
    width: 50vh;
    height: auto;
    position: static;
  }
}
