.footer-container {
  background-color: var(--primary-color);
  box-shadow: 0 1px 6px 0 #666666;
  color: #fff;
}
a.footer-contact {
  color: #fff;
  text-decoration: none;
}
a.footer-contact:hover {
  color: var(--secondary-color);
  text-decoration: none;
}
a.socialmedia-icon {
  color: #fff;
  text-decoration: none;
}
a.socialmedia-icon:hover {
  color: var(--secondary-color);
  text-decoration: none;
}
