.services-title {
  color: var(--secondary-color);
}

.services-row {
  display: flex;
}
.services-col {
  display: 1;
  padding: 1rem;
}
